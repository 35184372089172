
import {
  Vue, Component, Model, Prop, Watch,
} from 'vue-property-decorator';
import fuzzysort from 'fuzzysort';
import { sleep, randomAssetNameForDemo } from '@/utils/helpers';

@Component
export default class Dropdown extends Vue {
  @Model('change', { type: Array }) dropdownValues!: any

  @Prop({ type: Array, default: [] }) options!: any;

  @Prop({
    type: Object,
    default() {
      return {};
    },
  }) optionsSpeedDict?: any;

  @Prop({ type: Boolean, default: false }) multiselect!: boolean;

  @Prop({ type: Boolean, default: false }) hideConfirmBtn!: boolean;

  @Prop({ type: Boolean, default: false }) showCheckbox!: boolean;

  @Prop({ type: Boolean, default: false }) showCloseBtn!: boolean;

  @Prop({ type: Boolean, default: false }) isFixed!: boolean;

  @Prop({ type: Boolean, default: false }) showSelectAllBtn!: boolean;

  @Prop({ type: Boolean, default: false }) showInitials!: boolean;

  @Prop({ type: Array, default: () => [] }) selectionLocation!: any;


  @Prop({ type: Object, default: () => {} }) usersTasqsCount!: any;

  @Prop({ type: String, default: '' }) trackBy!: string;

  @Prop({ type: String, default: 'Search...' }) placeholder!: string;

  @Prop({ type: String, default: '' }) label!: string;

  @Prop({ type: String, default: '' }) typeOfData!: string;

  searchDropdownInput = ''

  randomAssetNameForDemo = randomAssetNameForDemo

  fuzzysortOptions: any[] = [];

  workingDropdownValues: any = []

  async created() {
    this.workingDropdownValues = JSON.parse(JSON.stringify(this.dropdownValues));
    this.workingDropdownValues = this.workingDropdownValues.filter((dropdownValue) => {
      let include = false;
      if (this.trackBy !== '') {
        const index = this.options.findIndex(
          (option) => option[this.trackBy] === dropdownValue[this.trackBy],
        );
        if (index !== -1) { include = true; }
      } else {
         include = this.options.includes(dropdownValue);
      }
      return include;
    });
    if (this.trackBy !== '') {
      for (let x = 0; x < this.options.length; x++) {
        this.fuzzysortOptions.push(this.options[x].text);
      }
    } else {
      this.fuzzysortOptions = JSON.parse(JSON.stringify(this.options));
    }

    await sleep(1000);
    this.$nextTick(() => {
      console.log(this.$refs);
      if (this.$refs.inputSearch) {
        (this.$refs.inputSearch as any).focus();
      }
    });
  }

  toggleSelect() {
    if (this.workingDropdownValues.length === this.options.length) {
      this.workingDropdownValues = [];
      this.$emit('change', []);
    } else {
      this.options.filter((o) => !this.workingDropdownValues.includes(o)).forEach((element) => {
        this.addValToDropdown(element);
      });
    }
  }

  userInitals(name) {
    let result = '';
    if (name && name.length) {
      result = name.charAt(0);
    }
    if (name && name.length) {
      const [firstName, lastName] = name.split(' ');
    if (firstName && lastName) {
      return firstName.charAt(0) + lastName.charAt(0);
    }
    }

    return result;
  }

  isSelected(option) {
    for (let x = 0; x < this.workingDropdownValues.length; x++) {
      if (this.trackBy !== '') {
        if (option.text === this.workingDropdownValues[x].text) {
          return true;
        }
      } else if (option === this.workingDropdownValues[x]) {
        return true;
      }
    }
    return false;
  }

  get filteredOptions() {
    if (this.searchDropdownInput.length === 0) {
      return this.options;
    }

    const results: any = [];
    const fuzzysortResults = fuzzysort.go(this.searchDropdownInput, this.fuzzysortOptions);
    for (let x = 0; x < fuzzysortResults.length; x++) {
      if (this.trackBy !== '') {
        if (
            Object.keys(this.optionsSpeedDict)
            && ![null, undefined].includes(this.optionsSpeedDict[fuzzysortResults[x].target])
          ) {
          results.push(this.optionsSpeedDict[fuzzysortResults[x].target]);
        } else {
          for (let y = 0; y < this.options.length; y++) {
            if (this.options[y].text === fuzzysortResults[x].target) {
              results.push(this.options[y]);
            }
          }
        }
      } else {
        // @ts-ignore
        results.push(fuzzysortResults[x].target);
      }
    }
    return results;
  }

  hideDropdown() {
    this.$emit('change', this.workingDropdownValues);
    this.$emit('confirm-dropdown');
    this.$emit('hide-dropdown');
  }

  addValToDropdown(val) {
    let didEmit = false;
    if (val.disabled != null && val.disabled) {
      return;
    }
    if (!this.multiselect) {
      while (this.workingDropdownValues.length) { this.workingDropdownValues.pop(); }
      this.workingDropdownValues.push(val);
      didEmit = true;
      this.$emit('change', [val]);
    } else {
      let containsVal = -1;
      for (let t = 0; t < this.workingDropdownValues.length; t++) {
           if (this.trackBy !== '') {
        if (this.workingDropdownValues[t].value === val.value) {
          containsVal = t;
          break;
        }
          } else if (this.workingDropdownValues[t] === val) {
          containsVal = t;
          break;
        }
      }
      if (containsVal !== -1) {
        const index = containsVal;
        if (index > -1) {
          this.workingDropdownValues.splice(index, 1);
        }
      } else {
        this.workingDropdownValues.push(val);
      }
    }

    if (!didEmit) {
      // this.$emit('change', this.workingDropdownValues);
    }
    this.$emit('close-dialog', this.workingDropdownValues);
  }

  @Watch('dropdownValues')
  onDropdownValuesUpdate() {
    this.workingDropdownValues = JSON.parse(JSON.stringify(this.dropdownValues));
  }
}
